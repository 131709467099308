import { ChainId } from '@luaswap/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.TOMOCHAIN_DEVNET]: '0x528C8b138D4a0A020EA16c60F33fe49b0684Af32',
  [ChainId.TOMOCHAIN_TESTNET]: '0x5841809531EB0708eED95c5D4Ba513595eC17C97',
  [ChainId.TOMOCHAIN_MAINNET]: '0xb67D7a6644d9E191Cac4DA2B88D6817351C7fF62'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
