import { JSBI, Pair, Percent, TokenAmount, ChainId, Token } from '@luaswap/sdk'
import { darken } from 'polished'
import React, { useState, useEffect } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { useTotalSupply } from '../../data/TotalSupply'

import { useActiveWeb3React } from '../../hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { ExternalLink, TYPE } from '../../theme'
import { IsTomoChain } from '../../utils'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { LP_FEE } from '../../utils/prices'
import { ButtonPrimary, ButtonSecondary, ButtonEmpty } from '../Button'
import { transparentize } from 'polished'
import { CardNoise } from '../earn/styled'

import { useColor } from '../../hooks/useColor'

import Card, { GreyCard, LightCard } from '../Card'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween, RowFixed } from '../Row'
import { Dots } from '../swap/styleds'
import QuestionHelper from '../QuestionHelper'
import { useFarmingContract } from '../../hooks/useContract'

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

export const HoverCard = styled(Card)`
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.bg2)};
  }
`

const RowFixedValue = styled(RowFixed)`
  min-width: 200px;
  justify-content: space-between;
`

const RowFixedTitle = styled(RowFixed)`
  position: relative;
  z-index: 1;
`

const StyledPositionCard = styled(LightCard)<{ bgColor: any }>`
  border: none;
  background: ${({ theme, bgColor }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${transparentize(0.8, bgColor)} 0%, ${theme.bg3} 100%) `};
  position: relative;
  overflow: hidden;
`

const Divider = styled.hr`
  width: 100%;
  border-width: 1px 0 0 0;
  border-color: #5aa2ff70;
  margin: 5px 0 3px;
`

interface PositionCardProps {
  pair: Pair
  farm?: any
  showUnwrapped?: boolean
  border?: string
}

export function MinimalPositionCard({ pair, showUnwrapped = false, border }: PositionCardProps) {
  const { account } = useActiveWeb3React()

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
        ]
      : [undefined, undefined]

  return (
    <>
      {userPoolBalance && JSBI.greaterThan(userPoolBalance.raw, JSBI.BigInt(0)) ? (
        <GreyCard border={border}>
          <AutoColumn gap="12px">
            <FixedHeightRow>
              <RowFixed>
                <Text fontWeight={500} fontSize={16}>
                  Your position
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <FixedHeightRow onClick={() => setShowMore(!showMore)}>
              <RowFixed>
                <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />
                <Text fontWeight={500} fontSize={20}>
                  {currency0.symbol}/{currency1.symbol}
                </Text>
              </RowFixed>
              <RowFixed>
                <Text fontWeight={500} fontSize={20}>
                  {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <AutoColumn gap="4px">
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  Your pool share:
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  {poolTokenPercentage ? poolTokenPercentage.toFixed(6) + '%' : '-'}
                </Text>
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  {currency0.symbol}:
                </Text>
                {token0Deposited ? (
                  <RowFixed>
                    <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                      {token0Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  {currency1.symbol}:
                </Text>
                {token1Deposited ? (
                  <RowFixed>
                    <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                      {token1Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
            </AutoColumn>
          </AutoColumn>
        </GreyCard>
      ) : (
        <LightCard>
          <TYPE.subHeader style={{ textAlign: 'center' }}>
            <span role="img" aria-label="wizard-icon">
              ⭐️
            </span>{' '}
            By adding liquidity you&apos;ll earn {LP_FEE} of all trades on this pair proportional to your share of the
            pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.
          </TYPE.subHeader>
        </LightCard>
      )}
    </>
  )
}

export default function FullPositionCard({ pair, border, farm }: PositionCardProps) {
  const { chainId, account } = useActiveWeb3React()
  const IsTomo = IsTomoChain(chainId)
  const ID = IsTomo ? 88 : 1
  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  // total user lp balance (include staked and available)
  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const userFarmBalance = new TokenAmount(pair.liquidityToken, farm ? farm.userStaked : '0')
  const totalUserBalance = userPoolBalance?.add(userFarmBalance)

  // total user pool share (include staked and available)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!totalUserBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, totalUserBalance.raw)
      ? new Percent(totalUserBalance.raw, totalPoolTokens.raw)
      : undefined

  // user farming pool share
  const totalFarmingTokens = new TokenAmount(pair.liquidityToken, farm ? farm.totalStaked : '0')

  const farmingTokenPercentage =
    !!userFarmBalance && !!totalFarmingTokens && totalFarmingTokens.toSignificant(1) !== '0'
      ? new Percent(userFarmBalance.raw, totalFarmingTokens.raw)
      : undefined

  // total user token deposited (include staked and available)
  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!totalUserBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, totalUserBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, totalUserBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, totalUserBalance, false)
        ]
      : [undefined, undefined]

  // user pending reward
  const LUA = IsTomo ? 
  new Token(ChainId.MAINNET, '0xB1f66997A5760428D3a87D68b90BfE0aE64121cC', 18, 'LUA', 'LUA') : 
  new Token(ChainId.TOMOCHAIN_MAINNET, '0x7262fa193e9590B2E075c3C16170f3f2f32F5C74', 18, 'LUA', 'LUA')
  const pendingReward = new TokenAmount(LUA, farm ? farm.pendingReward : '0')

  const backgroundColor = useColor(pair?.token0)

  // stake, unstake, harvest
  const farmingContract = useFarmingContract()
  const [harvest, setHarvest] = useState(false)

  const harvestReward = async () => {
    if (!farmingContract || !farm || harvest) return

    setHarvest(true)

    try {
      await farmingContract.claimReward(farm.pid)
      setHarvest(false)
    } catch (e) {
      setHarvest(false)
    }
  }

  const [symbolLiquidityToken, setSymbolLiquidityToken] = useState('')
  //@ts-ignore
  const farmPools = window.pools
  useEffect(() => {
    if (farm) {
      setSymbolLiquidityToken(farm.symbol)
    } else {
      for (let i = 0; i < farmPools.length; i++) {
        if (pair.liquidityToken.address.toLowerCase() === farmPools[i].lpAddresses[ID].toLowerCase()) {
          setSymbolLiquidityToken(farmPools[i].symbol)
        }
      }
    }
  }, [ID, farmPools])

  return (
    <StyledPositionCard border={border} bgColor={backgroundColor}>
      <CardNoise />
      <AutoColumn gap="12px">
        <FixedHeightRow>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />
            <Text fontWeight={500} fontSize={20}>
              {!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0.symbol}/${currency1.symbol}`}
            </Text>
          </RowFixed>

          <RowFixed gap="8px">
            <ButtonEmpty
              padding="6px 8px"
              borderRadius="12px"
              width="fit-content"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? (
                <>
                  {' '}
                  Manage
                  <ChevronUp size="20" style={{ marginLeft: '10px' }} />
                </>
              ) : (
                <>
                  Manage
                  <ChevronDown size="20" style={{ marginLeft: '10px' }} />
                </>
              )}
            </ButtonEmpty>
          </RowFixed>
        </FixedHeightRow>

        {showMore && (
          <AutoColumn gap="8px">
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                Your total pool tokens:
              </Text>
              <Text fontSize={16} fontWeight={500}>
                {totalUserBalance ? new BigNumber(totalUserBalance.toFixed(6)).toFormat() : '-'}
              </Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <RowFixed>
                <Text fontSize={16} fontWeight={500}>
                  Pooled {currency0.symbol}:
                </Text>
              </RowFixed>
              {token0Deposited ? (
                <RowFixed>
                  <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                    {new BigNumber(token0Deposited?.toFixed(6)).toFormat()}
                  </Text>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency0} />
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <RowFixed>
                <Text fontSize={16} fontWeight={500}>
                  Pooled {currency1.symbol}:
                </Text>
              </RowFixed>
              {token1Deposited ? (
                <RowFixed>
                  <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                    {new BigNumber(token1Deposited?.toFixed(6)).toFormat()}
                  </Text>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency1} />
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                Your total pool share:
              </Text>
              <Text fontSize={16} fontWeight={500}>
                {poolTokenPercentage ? new BigNumber(poolTokenPercentage.toFixed(5)).toFormat() + '%' : '-'}
              </Text>
            </FixedHeightRow>

            <Divider />
            {!IsTomo ? (
              <>
                <FixedHeightRow>
                  <RowFixed>
                    <Text fontSize={16} fontWeight={500}>
                      Farming pool tokens:
                    </Text>
                  </RowFixed>
                  <RowFixedValue>
                    <Text fontSize={16} fontWeight={500}>
                      {userFarmBalance ? new BigNumber(userFarmBalance.toFixed(6)).toFormat() : '-'}
                    </Text>
                    {farm && userFarmBalance?.greaterThan('0') && (
                      <ButtonSecondary
                        as={Link}
                        to={`/farming/${symbolLiquidityToken}`}
                        padding="3px 10px"
                        borderRadius="8px"
                        fontSize="12px"
                        width="fit-content"
                      >
                        Unstake
                      </ButtonSecondary>
                    )}
                  </RowFixedValue>
                </FixedHeightRow>

                <FixedHeightRow>
                  <Text fontSize={16} fontWeight={500}>
                    Your farming pool share:
                  </Text>
                  <RowFixedValue>
                    <Text fontSize={16} fontWeight={500}>
                      {farmingTokenPercentage ? new BigNumber(farmingTokenPercentage.toFixed(5)).toFormat() + '%' : '-'}
                    </Text>
                  </RowFixedValue>
                </FixedHeightRow>

                <FixedHeightRow>
                  <RowFixedTitle>
                    <Text fontSize={16} fontWeight={500}>
                      Available pool tokens <QuestionHelper text="Stake pool tokens to earn LUA tokens" />:
                    </Text>
                  </RowFixedTitle>
                  <RowFixedValue>
                    <Text fontSize={16} fontWeight={500}>
                      {userPoolBalance ? new BigNumber(userPoolBalance.toSignificant(8)).toFormat() : '-'}
                    </Text>
                    {userPoolBalance?.greaterThan(`0`) && (
                      <ButtonSecondary
                        as={Link}
                        to={`/farming/${symbolLiquidityToken}`}
                        padding="3px 10px"
                        fontSize="12px"
                        width="fit-content"
                      >
                        Stake
                      </ButtonSecondary>
                    )}
                  </RowFixedValue>
                </FixedHeightRow>

                <FixedHeightRow>
                  <RowFixed>
                    <Text fontSize={16} fontWeight={500}>
                      LUA reward:
                    </Text>
                  </RowFixed>
                  <RowFixedValue>
                    <Text fontSize={16} fontWeight={500}>
                      {pendingReward ? new BigNumber(pendingReward.toFixed(3)).toFormat() : '-'}
                    </Text>
                    <ButtonSecondary
                      padding="3px 10px"
                      borderRadius="8px"
                      fontSize="12px"
                      width="fit-content"
                      onClick={harvestReward}
                      disabled={harvest || pendingReward.equalTo('0')}
                    >
                      Harvest
                    </ButtonSecondary>
                  </RowFixedValue>
                </FixedHeightRow>

                <ButtonSecondary padding="8px" borderRadius="8px">
                  <ExternalLink
                    style={{ width: '100%', textAlign: 'center' }}
                    href={`https://info.luaswap.org/account/${account}`}
                  >
                    View accrued fees and analytics<span style={{ fontSize: '11px' }}>↗</span>
                  </ExternalLink>
                </ButtonSecondary>
              </>
            ) : (
              ''
            )}
            <RowBetween marginTop="10px">
              <ButtonPrimary
                padding="8px"
                as={Link}
                to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}
                width="48%"
              >
                Add
              </ButtonPrimary>
              <ButtonPrimary
                padding="8px"
                as={Link}
                width="48%"
                to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}
              >
                Remove
              </ButtonPrimary>
            </RowBetween>
          </AutoColumn>
        )}
      </AutoColumn>
    </StyledPositionCard>
  )
}
